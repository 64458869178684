<template>
  <div>NAVBAR {{user}}</div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object
    }
  },
  components: {
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
