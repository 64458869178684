<template>
  <div :class="wrapperClass">
    <div class="step-main">
      <div class="step-list" :class="[{ 'active' : step1 }, step > 1 ? 'completed' : '']"><i v-if="step > 1" class="fal fa-check"></i><span v-else>1</span></div>
      <div class="step-list" :class="[{ 'active' : step2 }, step > 2 ? 'completed' : '']"><i v-if="step > 2" class="fal fa-check"></i><span v-else>2</span></div>
      <div class="step-list" :class="[{ 'active' : step3 }, step > 3 ? 'completed' : '']"><i v-if="step > 3" class="fal fa-check"></i><span v-else>3</span></div>
      <div class="step-list" :class="[{ 'active' : step4 }, step > 4 ? 'completed' : '']"><i v-if="step == 4" class="fal fa-check"></i><span v-else>4</span></div>
    </div>
    <div class="step-line"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    step1() {
      return this.step == 1
    },
    step2() {
      return this.step == 2
    },
    step3() {
      return this.step == 3
    },
    step4() {
      return this.step == 4
    },
    wrapperClass() {
      if (this.step > 1) {
        return 'step-desktop'
      }
    },
    ...mapGetters(['step'])
  }
}
</script>
