<template>
    <nav :class="{ 'active': sidebarOpen }" id="sidebar">
      <div @click.prevent="toggleSidebar" id="dismiss">
        <i class="fal fa-times"></i>
      </div>
      <div class="sidebar-header">
        <router-link to="/"><img src="@images/logo_gray.png" class="img-fluid mb-4"></router-link>
      </div>
      <ul class="list-unstyled components">
        <li v-if="showNewBooking">
          <router-link to="/new-booking" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-plus fa-2x green-text"></i>
              </div>
              <div class="text mt-2">
                <span> Make new Booking </span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="showCalendar">
          <router-link to="/calendar" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-calendar-alt fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Calendar / Visits </span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="isClient">
          <router-link to="/properties" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-home fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Properties </span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="false">
          <router-link to="/invoices" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-receipt fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Invoices </span>
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/profile" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-user fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Profile </span>
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/billing" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-credit-card fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Billing </span>
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/referral" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fas fa-award fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span>Earn with WHC!</span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="isClient && false">
          <router-link to="/faq" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-life-ring fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Faq </span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="showLogout">
          <router-link to="/logout" class="icon">
            <div class="d-flex">
              <div class="sidebar-icon">
                <i class="fal fa-power-off fa-2x"></i>
              </div>
              <div class="text mt-2">
                <span> Sign Out </span>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
</template>
<script>
import $ from 'jquery'
import Navbar from './navbar.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { Navbar },
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapGetters(['sidebarOpen']),
    ...mapGetters({
      showCalendar: 'client/hasAnyBooking'
    }),
    showLogout() {
      return this.isClient
    },
    showNewBooking() {
      return this.isClient
    }
  },
  methods: mapActions(['toggleSidebar'])
}
</script>
