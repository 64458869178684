<template>
  <base-modal dom-id="detailsModal" :show="show" bodyClass="modal-body white-theme" :hide-header="true" :hide-footer="true" @close="$emit('close')">
    <div slot="content">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="text-center mt-2">
        <a href="/"><img src="@images/logo_gray.png" class="mw-300"/></a>
      </div>
      <reservation-summary v-if="show" />
    </div>
  </base-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ReservationSummary from './reservation_summary.vue'
import BaseModal from '@/modals/base_modal.vue'
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  components: { ReservationSummary, BaseModal },
  computed: {
    ...mapGetters({
      accountConfirmed: 'client/client_confirmed',
      confirmationUrl: 'client/confirmation_url',
      reservationId: 'reservation/reservationId',
      confirmationDetails: 'reservation/confirmation_details',
      confirmationText: 'reservation/confirmation_text',
      title: 'reservation/confirmation_title',
      mapImgSrc: 'property/map_img_src'
    })
  }
}
</script>
