<template>
  <div style="overflow: scroll; height: 500px;">
    <div v-html="content"></div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
