<template>
  <base-modal dom-id="termsModal" :show="show" mainClasses="terms-modal" bodyClass="modal-body" :hide-header="true" :hide-footer="true" @close="closeTermsModal">
    <div slot="content">
      <button type="button" class="close" @click="closeTermsModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <terms :content="terms"></terms>
    </div>
  </base-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BaseModal from '@/modals/base_modal.vue'
import Terms from './terms.vue'
import api from '@/clients/api'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  components: { BaseModal, Terms },
  data() {
    return {
      terms: ''
    }
  },
  mounted() {
    this.loadTerms()
  },
  methods: {
    ...mapActions(['closeTermsModal']),
    loadTerms() {
      api.getTerms().then(response => {
        if (response.data) {
          this.terms = response.data.value
        }
      })
    }
  }
}
</script>
