<template>
  <div class="debug">
    <pre style="color: #fff;font-size: 10px">
    client<br>{{client}}
    property<br>{{property}}
    propertyPicked : {{propertyPicked}}
    accountConfirmed : {{accountConfirmed}}
    isAuthenticated : {{isAuthenticated}}
    loading : {{loading}}
    minDate : {{minDate}}
    isBooking : {{isBooking}}
    isLocationIncomplete : {{isLocationIncomplete}}
    finishedBooking : {{finishedBooking}}
    currentStep : {{currentStep}}
    prevStep : {{prevStep}}
    currentUser : {{currentUser}}
    notBooking, : {{notBooking}}
    teamRequired : {{teamRequired}}
    firstStep : {{firstStep}}
    secondStep : {{secondStep}}
    thirdStep : {{thirdStep}}
    fourthStep : {{fourthStep}}
    showSteps : {{showSteps}}
    showSummary : {{showSummary}}
    </pre>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: mapGetters([
    'accountConfirmed', 'reservationId', 'isAuthenticated',
    'termsAccepted', 'loading', 'minDate', 'indicatorMessage',
    'isBooking', 'isLocationIncomplete', 'finishedBooking', 'client',
    'currentStep', 'propertyPicked', 'prevStep', 'currentUser', 'notBooking',
    'property', 'zip_code', 'teamRequired', 'firstStep', 'secondStep',
    'thirdStep', 'fourthStep', 'zipCode', 'showSteps', 'showSummary'
  ])
}
</script>
