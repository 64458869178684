<template>
  <div>
    <div v-html="html" />
    <spinner :show="html == ''" :use-modal="false" message="Loading" />
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
export default {
  components: { Spinner },
  data() {
    return { html: '' }
  },
  mounted() {
    this.$http.get('/api/reservations/confirmation').then(response => {
      this.html = response.data
    })
  }
}
</script>
