<template>
  <ValidationObserver ref="form" v-slot="{ validate, validated, valid, invalid, errors }">
    <b-form @submit.prevent="validate().then(onSubmit)" @reset="resetForm" v-bind="{ valid, invalid, validated, errors }" :class="$attrs.class" novalidate :validated="false" class="pb-xs-5">
      <slot v-bind="{ valid, validated, invalid, errors, formIsReadonly }"></slot>
      <div v-if="!noButtons" class="mb-0 mt-3">
        <slot name="buttons" v-bind="{ valid, validated, invalid, errors, editMode, formIsReadonly }" v-if="editMode">
          <div class="btn-set">
            <b-button :disabled="invalid && validated" variant="primary" type="submit">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
            <a class="btn btn-primary" href="#" @click="cancelEditMode">Cancel</a>
          </div>
        </slot>
      </div>
    </b-form>
  </ValidationObserver>
</template>
<script>

import BaseForm from './base_form.vue'

export default {
  inheritAttrs: false,
  extends: BaseForm,
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    startInEditMode: {
      type: Boolean,
      default: true
    },
    reportSuccess: {
      type: Boolean,
      default: false
    }
  }
}
</script>
